/* You can add global styles to this file, and also import other style files */



/*========================================
//
//  ###    ###    ###    ##  ##     ##
//  ## #  # ##   ## ##   ##  ####   ##
//  ##  ##  ##  ##   ##  ##  ##  ## ##
//  ##      ##  #######  ##  ##    ###
//  ##      ##  ##   ##  ##  ##     ##
//
//======================================== */

.hero {
  padding-top: 25px;

}

.hero .txt-block {
  margin-bottom: 35px;
}

.hero .txt-block h1 {
  font-size: 38px;
  color: #3d3d3d;
  /* margin-bottom: 25px; */
}

.hero .txt-block p {
  font-size: 18px;
  color: #3d3d3d;
  margin-bottom: 25px;
}

.hero .btn-orange {
  font-size: 20px;
}
.intro{
  text-align: center;
}
.intro h2{
  margin-bottom: 45px;
}
.intro p{
  font-size: 20px;
  line-height: 150%;
  max-width: 800px;
  margin: 0 auto;
  color: #5e5e5e;
}

.nav-menu{
  margin-bottom: 15px;
}
.bestelling {
  background-color: #f8f5f2;
  padding-top: 25px;
  padding-bottom: 25px;
}



.bestelling h2,
.bestelling h3 {
  margin-bottom: 10px;
}

.bestelling .menu-item {
  background-color: white;
  padding: 10px 8px;
  margin-bottom: 15px;
  cursor: pointer;
}

.bestelling .menu-item:hover {
  background: #eef7fa;
  box-shadow: 0 1px 1px #cfcac4
}

.bestelling .menu-item .title {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: #3d3d3d;
  font-size: 1rem;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
  margin-bottom: 15px;
}

.bestelling .menu-item .btn-more {
  padding: 3px 10px;
}
.bestelling .menu-item .btn-orange {
  padding: 3px 10px;
}
.bestelling .menu-item .ingredient{
  margin-bottom: 15px;
  padding-top: 10px;
  display: block;
  color: #0a3847;
font-weight: 300;
}
.bestelling .nav-category {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #f8f5f2;
  z-index: 1;
overflow-y: auto;
}

.btns-group li {
  display: block;
  margin-bottom: 10px;
}

.btns-group li div {
  text-decoration: none;
  background-color: #f36805;
  color: white;
  padding: .2em 1em;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  font-family: 'Roboto', sans-serif;
  border-radius: 50px;
  display: block;
  font-size: 14px;
}


/*=================================================================
//
//   ####   #####   ##     ##  ######  #####  ##     ##  ######
//  ##     ##   ##  ####   ##    ##    ##     ####   ##    ##
//  ##     ##   ##  ##  ## ##    ##    #####  ##  ## ##    ##
//  ##     ##   ##  ##    ###    ##    ##     ##    ###    ##
//   ####   #####   ##     ##    ##    #####  ##     ##    ##
//
//=================================================================*/

.hero-content{
  height: 40vh;
  min-height: 400px;
}
.hero-content .container{
  height: 100%;
  position: relative;
}

.hero-content .subcontent{
  /*position: absolute;*/
  padding-top: 20px;
  top: 20%;
  left: 0;
  max-width: 100%;
  font-size: 1em;
}

.hero-content h2{

 /* transform: translateY(-50%);*/
  background: rgba(238, 131, 8, 0.8);
  color: white;
  padding: 20px 40px;
  text-transform: uppercase;
  max-width: 100%;
  font-size: 1em;
}

.hero-content h3{

background: rgba(255,255,255,0.5);
  font-size: 38px;
  padding: 5px;

}

.beoordelingen {
  background-color: #f8f5f2;
  padding-top: 45px;
  padding-bottom: 45px;
}

.item-block {
  background-color: white;
  padding: 25px 8px;
  margin-bottom: 15px;
}

.item-block .title {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: #3d3d3d;
  font-size: 22px;
  display: block;
}
.item-block .stars{
  float: right;
}
.item-block .item-user{
  margin: 0 auto;
  border-radius: 50%;
  border: 2px solid #f36805;
  text-align: center;
  font-size: 120px;
  vertical-align: middle;
  text-align: center;
  padding: 3px 5px 5px 6px;
}
.icon-star-full{
  color: #dacb01;
  font-size: 20px;
}
.icon-star-empty{
  color: #fff;
  font-size: 20px;
  text-shadow: 0px 0px 3px #dacb01;
}
.contact{
  padding-top: 45px;
  padding-bottom: 45px;
}
.contact h1{
  margin-bottom: 35px;
}


.openhours-contact{
  border: 1px solid #3d3d3d;
  padding: 15px;
}
.openhours-contact:first-child{
  margin-bottom: 25px;
}
.openhours-contact ul li{
  border-bottom: 1px dotted #f8f5f2;
  margin-bottom: 7px;;
  }
.openhours-contact ul li span{
float: right;
}

.cart{
  float: right;
  z-index: 1;
}
.cart .cart-btn{
  background: #357960;
  border-color: #357960;
  border-radius: .25em;
  /* padding: 22px 10px 19px; */
  padding: 5px;
}

.catheader{
  border-bottom-style: solid;
border-width: 2px;
  padding-top: 10px;
  border-color: rgba(0,0,0,0.5);
}

.menu-row{
  padding-top:10px;
}

/*=====================================================
//
//  #####   #####    #####   ######  #####  #####
//  ##     ##   ##  ##   ##    ##    ##     ##  ##
//  #####  ##   ##  ##   ##    ##    #####  #####
//  ##     ##   ##  ##   ##    ##    ##     ##  ##
//  ##      #####    #####     ##    #####  ##   ##
//
//=====================================================*/

footer {
  background-color: #1a1a1a;
  color: white;
  padding-top: 45px;
  padding-bottom: 45px;
}
footer h3{
  margin-bottom: 35px;
  margin-top: 35px;
}
footer .poweredby-wrapper h3{
  margin-top: 0;
}
footer .poweredby{
  display: flex;
  flex-wrap: nowrap;
}




/*==================================================================================
//
//  #####    #####   ####  #####    #####   ##     ##   ####  ##  ##   ##  #####
//  ##  ##   ##     ##     ##  ##  ##   ##  ####   ##  ##     ##  ##   ##  ##
//  #####    #####   ###   #####   ##   ##  ##  ## ##   ###   ##  ##   ##  #####
//  ##  ##   ##        ##  ##      ##   ##  ##    ###     ##  ##   ## ##   ##
//  ##   ##  #####  ####   ##       #####   ##     ##  ####   ##    ###    #####
//
//==================================================================================*/

@media (max-width: 575px) {
  .hero {
      padding-top:5px;
      padding-bottom: 5px
  }

  .hero .txt-block {
      margin-bottom: 5px
  }

  .intro {
      padding-top: 5px;
      padding-bottom: 5px
  }

  h2{
    font-size: 1.3em !important;
  }

  h3{
    font-size: 1.2em !important;
  }

  .korting-headerr {
    font-size: 0.7em !important;

  }

  .bestelling h2,.bestelling h3 {
      margin-bottom: 5px
  }

  .bestelling {
      padding-top: 5px;
      padding-bottom: 5px
  }
  .category-desc{
    font-size: 14px !important;
  }

  footer .logo{
    max-height: 50px;
  }


  .scroll-top-button-wrapper{
    right: unset !important;
    left: 1rem;
    font-size: 20px !important;
  }

}



@media (min-width: 576px) {
  .navbar-brand img {
      max-height: 80px;
      max-width: 100%;
  }
  .hero .txt-block {
      position: relative;
  }
  .hero .txt-block .middle {
    text-align: center;
  }
  .bestelling .menu-item .title{
      margin-bottom: 0px;
  }
  .bestelling .menu-item .ingredient{
      margin-bottom: 15px;
  }
  footer h3{
      margin-top: 0;
  }
}

@media (min-width: 768px) {
  .navbar{
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
  }
  .hero .txt-block h1 {
      font-size: 48px;
      color: #3d3d3d;
      /* margin-bottom: 40px; */
  }
  .hero .txt-block p {
      font-size: 22px;
      margin-bottom: 40px;
  }
  .hero .btn-orange {
      font-size: 20px;
  }
  .bestelling {
      padding-top: 55px;
      padding-bottom: 55px;
  }
  .bestelling h2 {
      text-align: center;
      margin-bottom: 55px;
  }
  .bestelling .menu-row {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      align-items: flex-start;
  }
  .bestelling .menu-item {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 48%;
      max-width: 48%;
      padding: 10px 15px;
      margin-left: 0px;
  }
  .bestelling .menu-item:nth-child(2n+1) {
      margin-right: 4%;
  }
  .bestelling .menu-item:nth-child(2n+3) {
      clear: left;
  }
  .bestelling .menu-item .btn-more{
      margin-bottom: 10px;
  }
  .bestelling .menu-item .ingredient{
      margin-bottom: 20px;
  }
  .bestelling .menu-item .title{
    font-size: 22px;
}

.bestellingen h3{
  font-size: 1.25rem;
}


  .btns-group {
      padding-top: 0px;
  }
  .btns-group li {
      display: inline-block;
      margin-right: 10px;
  }
   /*=== Content ===*/

  .item-row {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  }
  .item-block {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 48%;
      max-width: 48%;
      padding: 25px 0px;
      margin-left: 0px;
  }
  .item-block:nth-child(2n+1) {
      margin-right: 4%;
  }
  .item-block:nth-child(2n+3) {
      clear: left;
  }


  footer .btn{
      font-size: 20px;
  }

   /* Sticky footer */
   main {
      margin-bottom: -309px;
  }
  footer,
  main:after {
      height: 309px;
  }

  .headertitle{
   /* position: absolute;
   margin-left: 120px !important;
   top: 0;
   padding-top: 15px; */
   font-size: 2em !important;
}

.fbox2{
  order:2;
}

.fbox3{
  order:1;
}

.fbox1{
  order:3;
}


.hero-content h3 {
	padding: 20px 40px;
}


}

@media (min-width: 992px) {
  h2{
      font-size: 38px;
  }
  h3{
      font-size: 34px;
  }
  .hero .txt-block h1 {
      font-size: 54px;
  }
  .hero-content h1{
      font-size: 2.3em;
  }
  .navbar-toggleable-sm .navbar-nav .nav-link {
      padding-left: 1em;
      padding-right: 1em;
  }
  .intro{
      padding-top: 20px;
      padding-bottom: 20px;

  }
  /* .bestelling .menu-item .btn-more,
  .bestelling .menu-item .ingredient{
      margin-bottom: 25px;
  } */

  .item-block .txt-block{
      padding-right: 0;
  }
  footer h2, footer h3{
      font-size: 30px;
  }
}

@media (min-width: 1200px) {}

.back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display:none;
font-size: larger;
}

a.back-to-top:hover{
  text-decoration: none !important;
}


/* .navbar-collapse.collapse.show {
  height: 100vh;
} */

@media (min-width: 768px){
.navbar-expand-md .navbar-collapse {
  margin-top: 10px;
}
}

.restaurantnotice{
  white-space: pre-wrap;
  overflow: auto;
  max-height: 180px;
  padding: 5px;
  font-size: 13px;
}

.ordernow{
  text-align: center;
  max-width: 800px;
  /* padding-top: 20px; */
}

.ordernow-button {
  background-color: rgba(238, 131, 8, 0.8);
  border: 2px solid #fff;
  color: #fff;
  line-height: 50px;
  height: 50px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  font-size: 16px;
  overflow: hidden;
  max-width: 250px;
}


label {
  display: inline-block;
  margin-bottom: .5rem;
}


/*!
 * Bootstrap v4.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */


 :root {
  --blue:#007bff;--indigo:#6610f2;--purple:#6f42c1;--pink:#e83e8c;--red:#dc3545;--orange:#fd7e14;--yellow:#ffc107;--green:#28a745;--teal:#20c997;--cyan:#17a2b8;--white:#fff;--gray:#6c757d;--gray-dark:#343a40;--primary:#007bff;--secondary:#6c757d;--success:#28a745;--info:#17a2b8;--warning:#ffc107;--danger:#dc3545;--light:#f8f9fa;--dark:#343a40;--breakpoint-xs:0;--breakpoint-sm:576px;--breakpoint-md:768px;--breakpoint-lg:992px;--breakpoint-xl:1200px;--font-family-sans-serif:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";--font-family-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace}

*,::after,::before {
  box-sizing: border-box
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent
}

@-ms-viewport {
  width: device-width
}

article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section {
  display: block
}

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff
}

[tabindex="-1"]:focus {
  outline: 0!important
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

h1,h2,h3,h4,h5,h6 {
  margin-top: 0;
  margin-bottom: .5rem
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

dl,ol,ul {
  margin-top: 0;
  margin-bottom: 1rem
}

b,strong {
  font-weight: bolder
}

small {
  font-size: 80%
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects
}

a:hover {
  color: #0056b3;
  text-decoration: underline
}

img {
  vertical-align: middle;
  border-style: none
}


button {
  border-radius: 0
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color
}

button,input,optgroup,select,textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

button,input {
  overflow: visible
}

button,select {
  text-transform: none
}

[type=reset],[type=submit],button,html [type=button] {
  -webkit-appearance: button
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit
}

.h1,h1 {
  font-size: 2.5rem
}

.h2,h2 {
  font-size: 2rem
}

.h3,h3 {
  font-size: 1.75rem
}

.h4,h4 {
  font-size: 1.5rem
}

.h5,h5 {
  font-size: 1.25rem
}

.h6,h6 {
  font-size: 1rem
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1)
}

.small,small {
  font-size: 80%;
  font-weight: 400
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 576px) {
  .container {
      max-width:540px
  }
}

@media (min-width: 768px) {
  .container {
      max-width:720px
  }
}

@media (min-width: 992px) {
  .container {
      max-width:960px
  }
}

@media (min-width: 1200px) {
  .container {
      max-width:1140px
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px
}

.col,.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-auto,.col-lg,.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-auto,.col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-auto,.col-sm,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-auto,.col-xl,.col-xl-1,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%
}

.order-first {
  -ms-flex-order: -1;
  order: -1
}

.order-last {
  -ms-flex-order: 13;
  order: 13
}

.order-0 {
  -ms-flex-order: 0;
  order: 0
}

.order-1 {
  -ms-flex-order: 1;
  order: 1
}

.order-2 {
  -ms-flex-order: 2;
  order: 2
}

.order-3 {
  -ms-flex-order: 3;
  order: 3
}

.order-4 {
  -ms-flex-order: 4;
  order: 4
}

.order-5 {
  -ms-flex-order: 5;
  order: 5
}

.order-6 {
  -ms-flex-order: 6;
  order: 6
}

.order-7 {
  -ms-flex-order: 7;
  order: 7
}

.order-8 {
  -ms-flex-order: 8;
  order: 8
}

.order-9 {
  -ms-flex-order: 9;
  order: 9
}

.order-10 {
  -ms-flex-order: 10;
  order: 10
}

.order-11 {
  -ms-flex-order: 11;
  order: 11
}

.order-12 {
  -ms-flex-order: 12;
  order: 12
}

.offset-1 {
  margin-left: 8.333333%
}

.offset-2 {
  margin-left: 16.666667%
}

.offset-3 {
  margin-left: 25%
}

.offset-4 {
  margin-left: 33.333333%
}

.offset-5 {
  margin-left: 41.666667%
}

.offset-6 {
  margin-left: 50%
}

.offset-7 {
  margin-left: 58.333333%
}

.offset-8 {
  margin-left: 66.666667%
}

.offset-9 {
  margin-left: 75%
}

.offset-10 {
  margin-left: 83.333333%
}

.offset-11 {
  margin-left: 91.666667%
}

@media (min-width: 576px) {
  .col-sm {
      -ms-flex-preferred-size:0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%
  }

  .col-sm-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none
  }

  .col-sm-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%
  }

  .col-sm-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%
  }

  .col-sm-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
  }

  .col-sm-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%
  }

  .col-sm-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%
  }

  .col-sm-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
  }

  .col-sm-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%
  }

  .col-sm-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%
  }

  .col-sm-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
  }

  .col-sm-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%
  }

  .col-sm-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%
  }

  .col-sm-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
  }
}

@media (min-width: 768px) {
  .col-md {
      -ms-flex-preferred-size:0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%
  }

  .col-md-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none
  }

  .col-md-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%
  }

  .col-md-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%
  }

  .col-md-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
  }

  .col-md-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%
  }

  .col-md-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%
  }

  .col-md-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
  }

  .col-md-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%
  }

  .col-md-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%
  }

  .col-md-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
  }

  .col-md-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%
  }

  .col-md-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%
  }

  .col-md-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
  }
}

@media (min-width: 992px) {
  .col-lg {
      -ms-flex-preferred-size:0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%
  }

  .col-lg-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none
  }

  .col-lg-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%
  }

  .col-lg-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%
  }

  .col-lg-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
  }

  .col-lg-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%
  }

  .col-lg-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%
  }

  .col-lg-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
  }

  .col-lg-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%
  }

  .col-lg-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%
  }

  .col-lg-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
  }

  .col-lg-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%
  }

  .col-lg-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%
  }

  .col-lg-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
  }
}

@media (min-width: 1200px) {
  .col-xl {
      -ms-flex-preferred-size:0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%
  }

  .col-xl-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none
  }

  .col-xl-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%
  }

  .col-xl-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%
  }

  .col-xl-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
  }

  .col-xl-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%
  }

  .col-xl-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%
  }

  .col-xl-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
  }

  .col-xl-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%
  }

  .col-xl-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%
  }

  .col-xl-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
  }

  .col-xl-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%
  }

  .col-xl-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%
  }

  .col-xl-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent
}

.table td,.table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6
}

.table .table {
  background-color: #fff
}

.table-sm td,.table-sm th {
  padding: .3rem
}

.table-bordered {
  border: 1px solid #dee2e6
}

.table-bordered td,.table-bordered th {
  border: 1px solid #dee2e6
}

.table-bordered thead td,.table-bordered thead th {
  border-bottom-width: 2px
}

.table-borderless tbody+tbody,.table-borderless td,.table-borderless th,.table-borderless thead th {
  border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.05)
}

.table-hover tbody tr:hover {
  background-color: rgba(0,0,0,.075)
}

.table-primary,.table-primary>td,.table-primary>th {
  background-color: #b8daff
}

.table-hover .table-primary:hover {
  background-color: #9fcdff
}

.table-hover .table-primary:hover>td,.table-hover .table-primary:hover>th {
  background-color: #9fcdff
}

.table-secondary,.table-secondary>td,.table-secondary>th {
  background-color: #d6d8db
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf
}

.table-hover .table-secondary:hover>td,.table-hover .table-secondary:hover>th {
  background-color: #c8cbcf
}

.table-success,.table-success>td,.table-success>th {
  background-color: #c3e6cb
}

.table-hover .table-success:hover {
  background-color: #b1dfbb
}

.table-hover .table-success:hover>td,.table-hover .table-success:hover>th {
  background-color: #b1dfbb
}

.table-info,.table-info>td,.table-info>th {
  background-color: #bee5eb
}

.table-hover .table-info:hover {
  background-color: #abdde5
}

.table-hover .table-info:hover>td,.table-hover .table-info:hover>th {
  background-color: #abdde5
}

.table-warning,.table-warning>td,.table-warning>th {
  background-color: #ffeeba
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1
}

.table-hover .table-warning:hover>td,.table-hover .table-warning:hover>th {
  background-color: #ffe8a1
}

.table-danger,.table-danger>td,.table-danger>th {
  background-color: #f5c6cb
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7
}

.table-hover .table-danger:hover>td,.table-hover .table-danger:hover>th {
  background-color: #f1b0b7
}

.table-light,.table-light>td,.table-light>th {
  background-color: #fdfdfe
}

.table-hover .table-light:hover {
  background-color: #ececf6
}

.table-hover .table-light:hover>td,.table-hover .table-light:hover>th {
  background-color: #ececf6
}

.table-dark,.table-dark>td,.table-dark>th {
  background-color: #c6c8ca
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe
}

.table-hover .table-dark:hover>td,.table-hover .table-dark:hover>th {
  background-color: #b9bbbe
}

.table-active,.table-active>td,.table-active>th {
  background-color: rgba(0,0,0,.075)
}

.table-hover .table-active:hover {
  background-color: rgba(0,0,0,.075)
}

.table-hover .table-active:hover>td,.table-hover .table-active:hover>th {
  background-color: rgba(0,0,0,.075)
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6
}

.table-dark {
  color: #fff;
  background-color: #212529
}

.table-dark td,.table-dark th,.table-dark thead th {
  border-color: #32383e
}

.table-dark.table-bordered {
  border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255,255,255,.05)
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255,255,255,.075)
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
      display:block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar
  }

  .table-responsive-sm>.table-bordered {
      border: 0
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
      display:block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar
  }

  .table-responsive-md>.table-bordered {
      border: 0
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
      display:block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar
  }

  .table-responsive-lg>.table-bordered {
      border: 0
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
      display:block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar
  }

  .table-responsive-xl>.table-bordered {
      border: 0
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

.table-responsive>.table-bordered {
  border: 0
}


.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media screen and (prefers-reduced-motion:reduce) {
  .form-control {
      transition: none
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25)
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:disabled,.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px)
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff
}

.form-control-file,.form-control-range {
  display: block;
  width: 100%
}


.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media screen and (prefers-reduced-motion:reduce) {
  .btn {
      transition: none
  }
}

.btn:focus,.btn:hover {
  text-decoration: none
}

.btn.focus,.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25)
}

.btn.disabled,.btn:disabled {
  opacity: .65
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer
}

.btn:not(:disabled):not(.disabled).active,.btn:not(:disabled):not(.disabled):active {
  background-image: none
}

a.btn.disabled,fieldset:disabled a.btn {
  pointer-events: none
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc
}

.btn-primary.focus,.btn-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.5)
}

.btn-primary.disabled,.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-primary:not(:disabled):not(.disabled).active,.btn-primary:not(:disabled):not(.disabled):active,.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf
}

.btn-primary:not(:disabled):not(.disabled).active:focus,.btn-primary:not(:disabled):not(.disabled):active:focus,.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.5)
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62
}

.btn-secondary.focus,.btn-secondary:focus {
  box-shadow: 0 0 0 .2rem rgba(108,117,125,.5)
}

.btn-secondary.disabled,.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-secondary:not(:disabled):not(.disabled).active,.btn-secondary:not(:disabled):not(.disabled):active,.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,.btn-secondary:not(:disabled):not(.disabled):active:focus,.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(108,117,125,.5)
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34
}

.btn-success.focus,.btn-success:focus {
  box-shadow: 0 0 0 .2rem rgba(40,167,69,.5)
}

.btn-success.disabled,.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-success:not(:disabled):not(.disabled).active,.btn-success:not(:disabled):not(.disabled):active,.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430
}

.btn-success:not(:disabled):not(.disabled).active:focus,.btn-success:not(:disabled):not(.disabled):active:focus,.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(40,167,69,.5)
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b
}

.btn-info.focus,.btn-info:focus {
  box-shadow: 0 0 0 .2rem rgba(23,162,184,.5)
}

.btn-info.disabled,.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-info:not(:disabled):not(.disabled).active,.btn-info:not(:disabled):not(.disabled):active,.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f
}

.btn-info:not(:disabled):not(.disabled).active:focus,.btn-info:not(:disabled):not(.disabled):active:focus,.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(23,162,184,.5)
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00
}

.btn-warning.focus,.btn-warning:focus {
  box-shadow: 0 0 0 .2rem rgba(255,193,7,.5)
}

.btn-warning.disabled,.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-warning:not(:disabled):not(.disabled).active,.btn-warning:not(:disabled):not(.disabled):active,.show>.btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500
}

.btn-warning:not(:disabled):not(.disabled).active:focus,.btn-warning:not(:disabled):not(.disabled):active:focus,.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255,193,7,.5)
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130
}

.btn-danger.focus,.btn-danger:focus {
  box-shadow: 0 0 0 .2rem rgba(220,53,69,.5)
}

.btn-danger.disabled,.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-danger:not(:disabled):not(.disabled).active,.btn-danger:not(:disabled):not(.disabled):active,.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d
}

.btn-danger:not(:disabled):not(.disabled).active:focus,.btn-danger:not(:disabled):not(.disabled):active:focus,.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(220,53,69,.5)
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5
}

.btn-light.focus,.btn-light:focus {
  box-shadow: 0 0 0 .2rem rgba(248,249,250,.5)
}

.btn-light.disabled,.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-light:not(:disabled):not(.disabled).active,.btn-light:not(:disabled):not(.disabled):active,.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled).active:focus,.btn-light:not(:disabled):not(.disabled):active:focus,.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(248,249,250,.5)
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124
}

.btn-dark.focus,.btn-dark:focus {
  box-shadow: 0 0 0 .2rem rgba(52,58,64,.5)
}

.btn-dark.disabled,.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled).active,.btn-dark:not(:disabled):not(.disabled):active,.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus,.btn-dark:not(:disabled):not(.disabled):active:focus,.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(52,58,64,.5)
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-outline-primary.focus,.btn-outline-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.5)
}

.btn-outline-primary.disabled,.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,.btn-outline-primary:not(:disabled):not(.disabled):active,.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,.btn-outline-primary:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.5)
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary.focus,.btn-outline-secondary:focus {
  box-shadow: 0 0 0 .2rem rgba(108,117,125,.5)
}

.btn-outline-secondary.disabled,.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,.btn-outline-secondary:not(:disabled):not(.disabled):active,.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(108,117,125,.5)
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-outline-success.focus,.btn-outline-success:focus {
  box-shadow: 0 0 0 .2rem rgba(40,167,69,.5)
}

.btn-outline-success.disabled,.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,.btn-outline-success:not(:disabled):not(.disabled):active,.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,.btn-outline-success:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(40,167,69,.5)
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info.focus,.btn-outline-info:focus {
  box-shadow: 0 0 0 .2rem rgba(23,162,184,.5)
}

.btn-outline-info.disabled,.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,.btn-outline-info:not(:disabled):not(.disabled):active,.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,.btn-outline-info:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(23,162,184,.5)
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning.focus,.btn-outline-warning:focus {
  box-shadow: 0 0 0 .2rem rgba(255,193,7,.5)
}

.btn-outline-warning.disabled,.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,.btn-outline-warning:not(:disabled):not(.disabled):active,.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,.btn-outline-warning:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255,193,7,.5)
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger.focus,.btn-outline-danger:focus {
  box-shadow: 0 0 0 .2rem rgba(220,53,69,.5)
}

.btn-outline-danger.disabled,.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,.btn-outline-danger:not(:disabled):not(.disabled):active,.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,.btn-outline-danger:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(220,53,69,.5)
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light.focus,.btn-outline-light:focus {
  box-shadow: 0 0 0 .2rem rgba(248,249,250,.5)
}

.btn-outline-light.disabled,.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,.btn-outline-light:not(:disabled):not(.disabled):active,.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,.btn-outline-light:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(248,249,250,.5)
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark.focus,.btn-outline-dark:focus {
  box-shadow: 0 0 0 .2rem rgba(52,58,64,.5)
}

.btn-outline-dark.disabled,.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,.btn-outline-dark:not(:disabled):not(.disabled):active,.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,.btn-outline-dark:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(52,58,64,.5)
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent
}

.btn-link.focus,.btn-link:focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none
}

.btn-link.disabled,.btn-link:disabled {
  color: #6c757d;
  pointer-events: none
}

.btn-group-lg>.btn,.btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem
}

.btn-group-sm>.btn,.btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem
}

.btn-block {
  display: block;
  width: 100%
}

.btn-block+.btn-block {
  margin-top: .5rem
}

input[type=button].btn-block,input[type=reset].btn-block,input[type=submit].btn-block {
  width: 100%
}

.fade {
  transition: opacity .15s linear
}

@media screen and (prefers-reduced-motion:reduce) {
  .fade {
      transition: none
  }
}

.fade:not(.show) {
  opacity: 0
}

.collapse:not(.show) {
  display: none
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease
}

@media screen and (prefers-reduced-motion:reduce) {
  .collapsing {
      transition: none
  }
}




.btn-group,.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle
}



.btn-group .btn+.btn,.btn-group .btn+.btn-group,.btn-group .btn-group+.btn,.btn-group .btn-group+.btn-group,.btn-group-vertical .btn+.btn,.btn-group-vertical .btn+.btn-group,.btn-group-vertical .btn-group+.btn,.btn-group-vertical .btn-group+.btn-group {
  margin-left: -1px
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start
}


.btn-group>.btn:first-child {
  margin-left: 0
}

.btn-group>.btn-group:not(:last-child)>.btn,.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,.btn-group>.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}




.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.nav-link {
  display: block;
  padding: .5rem 1rem
}

.nav-link:focus,.nav-link:hover {
  text-decoration: none
}

.nav-link.disabled {
  color: #6c757d
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-item {
  margin-bottom: -1px
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus,.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.nav-pills .nav-link {
  border-radius: .25rem
}

.nav-pills .nav-link.active,.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #007bff
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center
}

.tab-content>.tab-pane {
  display: none
}

.tab-content>.active {
  display: block
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .5rem 1rem
}

.navbar>.container,.navbar>.container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap
}

.navbar-brand:focus,.navbar-brand:hover {
  text-decoration: none
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none
}

.navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem
}

.navbar-toggler:focus,.navbar-toggler:hover {
  text-decoration: none
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%
}

@media (max-width: 575.98px) {
  .navbar-expand-sm>.container,.navbar-expand-sm>.container-fluid {
      padding-right:0;
      padding-left: 0
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
      -ms-flex-flow:row nowrap;
      flex-flow: row nowrap;
      -ms-flex-pack: start;
      justify-content: flex-start
  }

  .navbar-expand-sm .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute
  }

  .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem
  }

  .navbar-expand-sm>.container,.navbar-expand-sm>.container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap
  }

  .navbar-expand-sm .navbar-collapse {
      display: -ms-flexbox!important;
      display: flex!important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto
  }

  .navbar-expand-sm .navbar-toggler {
      display: none
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md>.container,.navbar-expand-md>.container-fluid {
      padding-right:0;
      padding-left: 0
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
      -ms-flex-flow:row nowrap;
      flex-flow: row nowrap;
      -ms-flex-pack: start;
      justify-content: flex-start
  }

  .navbar-expand-md .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute
  }

  .navbar-expand-md .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem
  }

  .navbar-expand-md>.container,.navbar-expand-md>.container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap
  }

  .navbar-expand-md .navbar-collapse {
      display: -ms-flexbox!important;
      display: flex!important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto
  }

  .navbar-expand-md .navbar-toggler {
      display: none
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg>.container,.navbar-expand-lg>.container-fluid {
      padding-right:0;
      padding-left: 0
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
      -ms-flex-flow:row nowrap;
      flex-flow: row nowrap;
      -ms-flex-pack: start;
      justify-content: flex-start
  }

  .navbar-expand-lg .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute
  }

  .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem
  }

  .navbar-expand-lg>.container,.navbar-expand-lg>.container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap
  }

  .navbar-expand-lg .navbar-collapse {
      display: -ms-flexbox!important;
      display: flex!important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto
  }

  .navbar-expand-lg .navbar-toggler {
      display: none
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl>.container,.navbar-expand-xl>.container-fluid {
      padding-right:0;
      padding-left: 0
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
      -ms-flex-flow:row nowrap;
      flex-flow: row nowrap;
      -ms-flex-pack: start;
      justify-content: flex-start
  }

  .navbar-expand-xl .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute
  }

  .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem
  }

  .navbar-expand-xl>.container,.navbar-expand-xl>.container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap
  }

  .navbar-expand-xl .navbar-collapse {
      display: -ms-flexbox!important;
      display: flex!important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto
  }

  .navbar-expand-xl .navbar-toggler {
      display: none
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.navbar-expand>.container,.navbar-expand>.container-fluid {
  padding-right: 0;
  padding-left: 0
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem
}

.navbar-expand>.container,.navbar-expand>.container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox!important;
  display: flex!important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto
}

.navbar-expand .navbar-toggler {
  display: none
}

.navbar-light .navbar-brand {
  color: rgba(0,0,0,.9)
}

.navbar-light .navbar-brand:focus,.navbar-light .navbar-brand:hover {
  color: rgba(0,0,0,.9)
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0,0,0,.5)
}

.navbar-light .navbar-nav .nav-link:focus,.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0,0,0,.7)
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0,0,0,.3)
}

.navbar-light .navbar-nav .active>.nav-link,.navbar-light .navbar-nav .nav-link.active,.navbar-light .navbar-nav .nav-link.show,.navbar-light .navbar-nav .show>.nav-link {
  color: rgba(0,0,0,.9);
}

.navactive{
  font-weight:900;
}

.navbar-light .navbar-toggler {
  color: rgba(0,0,0,.5);
  border-color: rgba(0,0,0,.1)
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}










.justify-content-end {
  -ms-flex-pack: end!important;
  justify-content: flex-end!important
}



.d-block {
  display: block!important
}


.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5
}

.close:focus,.close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none
}
.modal-open {
  overflow: hidden
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1055;
  display: none;
  overflow: hidden;
  outline: 0
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none
}

.modal.fade .modal-dialog {
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out;
  -webkit-transform: translate(0,-25%);
  transform: translate(0,-25%)
}

@media screen and (prefers-reduced-motion:reduce) {
  .modal.fade .modal-dialog {
      transition: none
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0,0);
  transform: translate(0,0)
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2))
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.show {
  opacity: .5
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef
}

.modal-footer>:not(:first-child) {
  margin-left: .25rem
}

.modal-footer>:not(:last-child) {
  margin-right: .25rem
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}

@media (min-width: 576px) {
  .modal-dialog {
      max-width:500px;
      margin: 1.75rem auto
  }

  .modal-dialog-centered {
      min-height: calc(100% - (1.75rem * 2))
  }

  .modal-sm {
      max-width: 300px
  }
}

@media (min-width: 992px) {
  .modal-lg {
      max-width:800px
  }
}


.pt-2,.py-2 {
  padding-top: .5rem!important
}

.pb-2,.py-2 {
  padding-bottom: .5rem!important
}



.text-left {
  text-align: left!important
}

.text-right {
  text-align: right!important
}

.text-center {
  text-align: center!important
}

.headertitle{
  margin: 0 10px;
  font-size: 1.0em;
  text-transform: uppercase;
  text-align: center;
}


.fcontainer {
  display: flex;
  width: 100%;
justify-content: space-between;
}
.fbox {
  /*! flex: 1; */
  display: flex;
  justify-content: center;
}
.fbox1 > span { margin-right: auto; }
.fbox2 > span { margin-left: auto;  }



/* non-essential */
.fbox {
  align-items: center;
}



/* HOME */

.icon-shopping-basket {
  margin-left: 3px;
  margin-right: 5px;
}

.korting-headerh {
  margin: auto;
  color: RED;
  display: inline;
  font-size: 25px;
}

.imageType {
  width: 300px;
}

.cursor-pointer {
  cursor: pointer;
}

.categories-select {
  color:#000 !important;
  width: 100%;
  font-size: 17px;
  margin-bottom: 5px;
}

.scrollable-height{
  height: 100vh!important;
}

.labelCheckbox {
  width: 50%;
}

.nav-category{
  padding-top: 5px;
  margin-top: -10px;
}

.count-label {
  border: solid 1px lightgray;
  font-size: 21px;
  width: 50px;
  margin: 0 5px;
  display: inline-block;
  text-align: center;
  height: 29px;
}

th, td {
  padding: 4px!important;
}


.align-text-center {
  text-align: center;
}


.errorselect {
  border: solid 1px red;
}

.customFontSizeIcons {
  font-size: 30px;
}

.customPaddingAddItemError {
  padding-left: 5px;
}

.disabled-font-color {
  color: lightslategray;
}

.basket-card-color-blue {
  /* color: #2196F3; */
  background-color: dodgerblue;
}

.item-add-modal-ingredients {
  border-bottom: solid 1px lightgray;
  padding-left: 5px;
  margin-bottom: 10px;
}


/* ---------------------------------------------------------------------------------------------------------- */

/* *---------------------------------------------------------------------------------------------- /
/* The container */
.containerExtraMenu {
  padding-right: 5px;
  font-size: 14px;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerExtraMenu input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.containerExtraMenu:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerExtraMenu input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerExtraMenu input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerExtraMenu .checkmark:after {
  left: 10px;
  top: 2px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.containerExtraMenu {
  box-shadow: none;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.menu-item-add-btn{
  right: 0;
  bottom: 0;
  padding-bottom: 8px;
  text-align: right!important;
}

li.active div{
  background-color: #357960 !important;
}

.bestelling h2 {
  text-align: center;
}


.menu-category-bar-container{
  height: 64px;
  position: relative;
}


.menu-category-bar{
  height: 64px;
display: flex;
flex-direction: row;
color: #0a3847;
max-width: 1130px;
margin: 0 auto;
position: relative;
justify-content: space-between;
}


.menu-category-search{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /*cursor: pointer;*/
  width: 100%;

max-width: 500px;

padding-right: 10px;
}
.menu-category-search-icon {
  background: #fff;
  border-top: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  height: 40px;
  line-height: 40px;
  width: 35px;
  text-align: center;
  color: rgb(181, 181, 181);
}
.menu-category-search-form {
  overflow: hidden;
  /* position: absolute; */
  /* width: calc(100% - 150px); */
  left: 50px;
  top: 0;
  display: flex;
  /* justify-content: space-between; */
  z-index: 2;
  /* margin: 0 0 0 -15px; */
  width: 100%;
}

.menu-category-search-label {
  /* padding: 12px 0; */
  width: 100%;
  margin-bottom: 0 !important;
}

.menu-category-search-input {
  height: 40px;
  /* padding: 0 10px 0 0; */
  width: 100%;
  border-radius: 2px;
  background: #fff;
  border-top: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  border-left: 0px;
  /* max-width: 500px; */
}


.menu-category-search-close-icon{
  background: #fff;
border-top: 1px solid #e7e7e7;
border-right: 1px solid #e7e7e7;
border-bottom: 1px solid #e7e7e7;
height: 40px;
line-height: 40px;
width: 35px;
text-align: center;
float: right;
}

.searchbox::after {
  content: "";
  clear: both;
  display: table;
}


.category-desc{
  margin: 0 0 0.5rem 0.5rem;
  color: #0a3847;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.85;
  position: relative;
}


.menu-item-inner{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
}


.menu-item-desc{
 margin: 0 15px;
}


.menu-item-name{
  align-self: flex-start;
}


.no_products_found {
  text-align: center;
  max-width: 75%;
  font-size: 15px;
  margin: 10vh auto 40px;
}

.no_products_found_text {
  margin: 14px 0 24px !important;
}

.no_products_found_icon{
  font-size:50px;
}

.reset-product-search{
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #d7d7d7;
  color: #357960;
  width: 226px;
  height: 48px;
  border-radius: 2px;
}

.reset-product-search:hover{
  cursor:pointer;
  background-color:#357960;
  color:#fff
}


/* END HOME */


/* REVIEWS */

.title {
  margin-bottom: 10px;
}

.date{
  font-style: italic;
}

.korting-headerr {
  font-size: 1em;
  color: black;

}

.page-headers > h2 {
  padding-bottom: 5px;
}

.hero-image  {
  background-position-x: center;
  position: absolute;
  opacity: 0.5;
  filter: alpha(opacity=50);
  z-index: -1;
  width:100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 500px;
}

@media screen and (max-width: 768px) {
  .hero-content {
      min-height: 45px;
  }
  .page-headers {
      padding-left: 20px;
      font-size: 22px;
  }
}

@media screen and (max-width: 460px) {

  .page-headers {
      padding-left: 20px;
      font-size: 18px;
  }

  .hero-content  {
      padding-left: 5px;
  }
}

@media screen and (min-width: 460px) {

  .hero-image  {
      background-size: contain;
  }
}

/* END REVIEWS*/

/* CONTACT */

agm-map {
  height: 300px;
}

.korting-headerc {
  margin: auto;
  color: RED;
  display: inline;
  font-size: 20px;
}


@media screen and (max-width: 768px) {
.korting-headerc {
  font-size: 17px;
}
}

/* END CONTACT */

.review-rating {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  margin: 0 0 4px;
  font-weight: bold;
}

.review-rating .review-stars {
  height: auto;
  width: auto;
  vertical-align: middle;
  margin-right: 4px;
}

.review-stars {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 70px;
  height: 12px;
  vertical-align: text-bottom;
}

.review-stars:before {
  content: "\e816\e816\e816\e816\e816";
  display: block;
  top: 0;
  left: 0;
  color: #999;
  font-family: "fontello";
  font-weight: 900;
  line-height: 1;
}
.review-rating .review-stars-range {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.review-stars-range {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 90%;
  height: 12px;
}
.review-stars-range:before {
  content: "\e816\e816\e816\e816\e816";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  color: #dacb01;
  font-family: "fontello";
  font-weight: 900;
  line-height: 1;
}

.cat-img{
  width:100%;
  border-radius: 4px;
}

.cookie_directive_container{
  background-color: #164969;
  min-height: 26px;
  font-size: 12px;
  color: #ccc;
  line-height: 18px;
  font-family: Poppins, "Trebuchet MS",Helvetica,sans-serif;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999999;
  }
  .cookie_directive_container p{
      margin:0;
  }
  .cookie_directive_container a{
      color:#F6F5D7;
  }

  .cookie_directive_container .credit{
    padding: 10px;
  }

  .meal-image {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    margin-right: 10px;
  }

  .meal-image img {
    object-fit:contain;
  }
